import React from 'react'
import Layout from './src/components/layout'
import ModeProvider from './context/ModeProvider'
// custom typefaces fontsource
import '@fontsource/lato/300.css' //  Weight light 300
import '@fontsource/lato/400.css' //  Weight light 400
import '@fontsource/lato/700.css' //  Weight Bold 800
import '@fontsource/lato/300-italic.css' //  Weight thin italic 300

export const wrapRootElement = ({ element }) => (
  <ModeProvider>{element}</ModeProvider>
)

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `This application has been updated. ` +
      `Reload to display the latest version?`
  )
  if (answer === true) {
    window.location.reload()
  }
}
