import styled, { keyframes } from 'styled-components'
import { Link } from 'gatsby'

export const WrapDiv = styled.div`
  place-content: center space-between;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
`
export const Main = styled.main`
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: max-content;
  margin: 0 auto;
  max-width: 100%;
  padding: 0 1.0875em 0;
  width: 1200px;
  position: relative;
`
export const Line = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.global.rust};
  margin-top: 1.66em;
  margin-bottom: 0.36em;
  width: 100%;
`
export const HeadStyle = styled.header`
  display: flex;
  flex-direction: column;
  padding: 2.25em 0.66em 1em;
  margin: 2.5em auto 0;
  max-width: 100%;
  width: 1600px;
  @media (min-width: 750px) {
    padding-top: 2em;
  }
  > h1 {
    display: flex;
    flex-direction: column;
    > span:first-of-type {
      color: ${({ theme }) => theme.global.blue};
      font-size: 1em;
      font-weight: 300;
      white-space: nowrap;
    }
    > span:last-of-type {
      font-size: 1.45em;
      font-weight: 300;
    }
  }
  > div {
    margin: 0 1.5em;
    @media screen and (max-width: 700px) {
      margin-right: 0;
    }
  }
  > div h2 {
    color: ${({ theme }) => theme.global.rust};
    font-style: italic;
    font-weight: 300;
  }
`
export const TitleH3 = styled.h3`
  color: ${({ theme }) => theme.global.rust};
  margin-top: 0.66em;
  transition: color 400ms ${({ theme }) => theme.cubbez};
`
export const ImgCard = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 450px;
  > a {
    display: flex;
    height: auto;
    width: 100%;
  }
  & a:hover + ${TitleH3}, a:focus + ${TitleH3} {
    color: ${({ theme }) => theme.global.blue};
  }
  & a:hover div,
  a:focus div {
    box-shadow: ${({ theme }) => theme.boxshdhov};
    overflow: hidden;
    transform: scale(1.005);
  }
  > a div {
    align-self: center;
    box-shadow: ${({ theme }) => theme.boxshdmid};
    cursor: pointer;
    transition: all 400ms ${({ theme }) => theme.cubbez};
    will-change: transform;
  }
`
export const MarginDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0.75em 0 1em;
  > h2,
  h3,
  h4,
  h5,
  h6 {
    text-align: center;
  }
`
export const TextDiv = styled.div`
  margin: 1.75em 0;
`
export const ExternalA = styled.a`
  align-self: center;
  backface-visibility: hidden;
  box-shadow: none;
  border-bottom: ${({ theme }) => theme.borderbtm};
  border-bottom-color: ${({ theme }) => theme.global.text};
  color: ${({ theme }) => theme.global.text};
  cursor: pointer;
  display: inline-block;
  font-size: inherit;
  font-weight: 400;
  margin-bottom: inherit;
  opacity: ${({ theme }) => theme.opctymid};
  padding: 0.25em 0 0.02em;
  text-align: center;
  text-transform: capitalize;
  transition: all 400ms ease-in-out;
  &:hover,
  :focus {
    color: ${({ theme }) => theme.global.blue};
    border-bottom-color: ${({ theme }) => theme.global.blue};
    opacity: ${({ theme }) => theme.opctyfull};
  }
`
export const StyledLink = styled(Link)`
  backface-visibility: hidden;
  box-shadow: none;
  border-bottom: ${({ theme }) => theme.borderbtm};
  border-bottom-color: ${({ theme }) => theme.global.text};
  color: ${({ theme }) => theme.global.text};
  cursor: pointer;
  display: inline-block;
  font-size: inherit;
  font-weight: 400;
  margin-bottom: inherit;
  opacity: ${({ theme }) => theme.opctymid};
  padding: 0.25em 0 0.02em;
  text-align: center;
  text-transform: capitalize;
  transition: all 400ms ease-in-out;
  &:hover,
  &:focus {
    color: ${({ theme }) => theme.global.blue};
    border-bottom-color: ${({ theme }) => theme.global.blue};
    opacity: ${({ theme }) => theme.opctyfull};
  }
`

export const TextLinksDiv = styled.div`
  > a,
  p a,
  div a {
    backface-visibility: hidden;
    border-bottom: ${({ theme }) => theme.borderbtm};
    border-bottom-color: ${({ theme }) => theme.global.text};
    color: ${({ theme }) => theme.global.text};
    display: inline-block;
    font-size: inherit;
    font-weight: 400;
    margin-bottom: inherit;
    opacity: ${({ theme }) => theme.opctymid};
    padding: 0.25em 0 0.02em;
    text-align: center;
    transition: all 400ms ease-in-out;
    &:hover,
    &:focus {
      border-bottom-color: ${({ theme }) => theme.global.blue};
      color: ${({ theme }) => theme.global.blue};
      opacity: ${({ theme }) => theme.opctyfull};
    }
  }
`
export const Button = styled.button`
  align-items: center;
  align-self: flex-end;
  background-color: ${({ theme }) => theme.global.body};
  border-radius: ${({ theme }) => theme.radius};
  border: 1px solid ${({ theme }) => theme.global.text};
  color: ${({ theme }) => theme.global.text};
  cursor: pointer;
  display: flex;
  font-size: 1.125rem;
  font-family: inherit;
  font-weight: 400;
  justify-content: center;
  margin: 0.8em 0.8em 1.125em 0.25em;
  padding: 0.25em 0.5em 0.265em;
  transition: all ${({ theme }) => theme.translng};
  width: 8rem;
  will-change: color;
  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.global.blue};
    color: ${({ theme }) => theme.global.body};
  }
`
export const Form = styled.form`
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: 33rem;
  > fieldset {
    display: flex;
    flex-direction: column;
    border: 0;
    margin-bottom: 0.33rem;
    margin-top: 0.33rem;
    padding-bottom: 0;
    text-align: left;
    > label {
      margin-left: 0.25rem;
      max-width: 100%;
      opacity: ${({ theme }) => theme.opctyhi};
    }
  }
`
export const Input = styled.input`
  background-color: ${({ theme }) => theme.white};
  border-color: ${({ theme }) => theme.global.rust};
  border-image: initial;
  border-radius: ${({ theme }) => theme.radius};
  border-style: solid;
  border-width: 1px;
  color: ${({ theme }) => theme.global.body};
  font-family: inherit;
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.5rem;
  padding: 0.36rem;
  width: 100%;
  &:focus,
  &:hover {
    border-color: ${({ theme }) => theme.bluedrk};
    outline: none;
  }
`
export const Textarea = styled.textarea`
  background-color: ${({ theme }) => theme.white};
  border-color: grey;
  border-image: initial;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  font-family: inherit;
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.5rem;
  padding: 0.36rem;
  width: 100%;
  &:focus,
  &:hover {
    border-color: ${({ theme }) => theme.bluedrk};
    outline: none;
  }
`
export const InputBtn = styled.input`
  align-items: center;
  align-self: flex-end;
  background-color: ${({ theme }) => theme.global.body};
  border-radius: ${({ theme }) => theme.radius};
  border: 1px solid ${({ theme }) => theme.global.text};
  color: ${({ theme }) => theme.global.text};
  cursor: pointer;
  display: flex;
  font-size: 1.125rem;
  font-family: inherit;
  font-weight: 400;
  justify-content: center;
  margin: 0.8em 0.8em 1.125em 0.25em;
  padding: 0.5em 0.265em;
  transition: all ${({ theme }) => theme.translng};
  width: 8rem;
  will-change: color;
  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.global.blue};
    color: ${({ theme }) => theme.global.body};
    cursor: pointer;
  }
  &:disabled,
  :disabled:hover,
  :disabled:focus {
    background-color: ${({ theme }) => theme.global.rust};
    color: lightgrey;
    cursor: not-allowed;
  }
`
export const Select = styled.select`
  background-color: ${({ theme }) => theme.white};
  border-color: grey;
  border-image: initial;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  font-family: inherit;
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.5rem;
  padding: 0.36rem;
  width: 100%;
  &:focus,
  &:hover {
    border-color: ${({ theme }) => theme.bluedrk};
    outline: none;
  }
`
export const RowDiv = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
`
export const GridDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(298px, 450px));
  grid-gap: 2em;
  justify-content: space-around;
  justify-items: center;
  max-width: 100%;
`
export const SVGSpan = styled.span`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  pointer-events: auto;
  > svg {
    height: 1rem;
  }
  > svg path {
    fill: ${({ theme }) => theme.global.blue};
  }
`
const scaleMap = keyframes`
  0% {
    background-color: ${({ theme }) => theme.greytns};
    box-shadow: ${({ theme }) => theme.boxshdhov};
    opacity: 0;
    transform: scale(0.012, 0.012) translateX(-200vw);
  }
  70% {
    background-color: ${({ theme }) => theme.global.rust};
    box-shadow: ${({ theme }) => theme.boxshdhov};
    opacity: ${({ theme }) => theme.opctyfull};
    transform: scale(1.005) translateX(0px);
  }
  100% {
    background-color: ${({ theme }) => theme.greylt};
    box-shadow: ${({ theme }) => theme.boxshd};
    transform: none;
  }
`
export const SVGDiv = styled.div`
  > a {
    align-items: center;
    animation: ${scaleMap} 1860ms ease-in-out 1;
    background-color: ${({ theme }) => theme.greylt};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: 1px solid ${({ theme }) => theme.accent};
    border-radius: 50%;
    box-shadow: ${({ theme }) => theme.boxshdmid};
    display: flex;
    height: 333px;
    justify-content: center;
    margin: -2em auto 1em;
    overflow: hidden;
    transition: all 400ms ${({ theme }) => theme.cubbez};
    width: 333px;
    will-change: transform color;
    @media screen and (max-width: 700px) {
      height: 300px;
      margin: 0.66em auto;
      width: 300px;
    }
  }
  > a:hover,
  a:focus {
    background-color: ${({ theme }) => theme.white};
    border: 2px solid ${({ theme }) => theme.greylt};
    box-shadow: ${({ theme }) => theme.boxshdhov};
    transform: scale(1.015);
    > svg path:nth-last-of-type(2) {
      fill: ${({ theme }) => theme.accent};
      transition: fill 400ms ${({ theme }) => theme.cubbez};
    }
  }
`
