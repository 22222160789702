import React, { useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'

import UpSVG from './svgs/upsvg'

// const isBrowser = typeof window !== 'undefined'

const fadeIn = keyframes`
	from {
		opacity: 0;
	}
	to {
		opacity: ${({ theme }) => theme.opctyhi};
	}
`

const UpButton = styled.button`
  animation: ${fadeIn} 400ms ease-in;
  background-color: transparent;
  border: none;
  bottom: 0.66em;
  color: ${({ theme }) => theme.global.text};
  cursor: pointer;
  height: auto;
  position: fixed;
  pointer-events: auto;
  right: 0.66em;
  transition: all ${({ theme }) => theme.translng};
  width: fit-content;
  will-change: transform;
  &:hover,
  :focus {
    opacity: ${({ theme }) => theme.opctyfull};
    transform: scale(1.12, 1.12);
  }
  @media (max-width: 750px) {
    bottom: 4.86em;
  }
`

const UpTop = () => {
  const [windowTrue, setWindowTrue] = useState(false)
  const [isVisible, setIsvisible] = useState(false)

  useEffect(() => setWindowTrue(true), [])

  const scrollToTop = () => {
    if (windowTrue) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }

  const toggleVisible = () => {
    if (windowTrue && window.scrollY > 750) {
      setIsvisible(true)
    } else {
      setIsvisible(false)
    }
  }

  useEffect(() => {
    if (windowTrue) {
      window.addEventListener('scroll', toggleVisible)
    }
    return () => {
      window.removeEventListener('scroll', toggleVisible)
    }
  })

  return (
    <>
      {isVisible && (
        <UpButton title="To Top" role="button" onClick={scrollToTop}>
          <UpSVG />
        </UpButton>
      )}
    </>
  )
}

export default UpTop
