import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { ExternalA, StyledLink } from './stylecomp/multi.styles'

import SocialIcons from './socialicons'
import UpTop from './uptop'
import BottomNav from './bottomnav'

const Foot = styled.footer`
  align-items: center;
  align-self: end;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  min-height: 2.2rem;
  padding: 0 0.125em 0.86em;
  text-align: center;
  width: 100%;
  > p {
    font-size: 0.68em;
    margin-bottom: 0;
  }
  @media (max-width: 750px) {
    padding-bottom: 4.125em;
  }
`
const StyledAdminLink = styled(StyledLink)`
  border-bottom: none;
`

const Footer = () => {
  const [date, setDate] = useState(null)

  useEffect(() => {
    setDate(new Date().getFullYear())
  }, [])

  return (
    <Foot>
      <SocialIcons />
      <p>Artworks protected by copyright &copy; {date} Jocelyn Ball-Hansen</p>
      <p>
        Read about the site's{' '}
        <StyledLink to="/privacy/">Privacy Policy</StyledLink>
      </p>
      <p>
        Website designed and{' '}
        <StyledAdminLink to="/adminjoss/">built</StyledAdminLink> by{' '}
        <ExternalA
          href="https://johnhansenartist.com/"
          target="_blank"
          rel="nofollow me noopener noreferrer"
        >
          John Hansen
        </ExternalA>{' '}
        using{' '}
        <ExternalA
          href="https://www.gatsbyjs.org/"
          target="_blank"
          rel="nofollow me noopener noreferrer"
        >
          Gatsby
        </ExternalA>
      </p>
      <UpTop />
      <BottomNav />
    </Foot>
  )
}

export default Footer
