import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import Jbh from '../../images/jbh.inline.svg'

const Span = styled.span`
  margin: 0 auto 0 0;
  > a {
    align-items: center;
    display: flex;
    justify-content: center;
    opacity: ${({ theme }) => theme.opctyhi};
    padding: 0 0.375em 0 0.375em;
    position: relative;
    transition: all 400ms ease-out;
    will-change: color, opacity;
    &.active {
      > svg g {
        stroke: ${({ theme }) => theme.global.rust};
      }
    }
    &::after {
      content: 'Home';
      color: ${({ theme }) => theme.global.rust};
      font-weight: 300;
      padding-left: 0.25em;
      position: relative;
      top: 0;
      left: 0.225rem;
      background-color: transparent;
      opacity: 0;
      transform: scale(0, 1);
      transition: all 400ms ease-out;
      transform-origin: left;
      will-change: color, opacity;
    }
    &:hover::after,
    :focus::after {
      color: ${({ theme }) => theme.global.blue};
      opacity: ${({ theme }) => theme.opctyhi};
      transform: scale(1, 1);
    }
    &.active::after {
      color: ${({ theme }) => theme.global.rust};
      opacity: ${({ theme }) => theme.opctyfull};
      transform: scale(1, 1);
    }
    > svg {
      height: 1.82rem;
    }
    > svg g {
      stroke: ${({ theme }) => theme.global.text};
      opacity: ${({ theme }) => theme.opctyhi};
      transition: stroke 400ms ${({ theme }) => theme.cubbez};
    }
    &:hover,
    :focus {
      > svg g {
        stroke: ${({ theme }) => theme.global.blue};
      }
    }
    &.active:hover::after,
    .active:focus::after {
      color: ${({ theme }) => theme.global.blue};
      opacity: ${({ theme }) => theme.opctyhi};
    }
  }
`
const JbSvg = () => (
  <Span>
    <Link to="/" activeClassName="active" aria-label="Home Link">
      <Jbh />
    </Link>
  </Span>
)

export default JbSvg
