import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import WatercolourSVG from './svgs/watercoloursvg'
import AcrylicSVG from './svgs/acrylicsvg'
import DrawingSVG from './svgs/drawingsvg'
import AboutSVG from './svgs/aboutsvg'
import OilSVG from './svgs/oilsvg'
import ContactSVG from './svgs/contactsvg'

const BottomDiv = styled.div`
  background-color: ${({ theme }) => theme.global.nav};
  border-top: 1px solid ${({ theme }) => theme.global.blue};
  bottom: 0;
  box-shadow:
    0px -1px 9px rgba(0, 0, 0, 0.24),
    0px -1px 20px rgba(0, 0, 0, 0.38);
  display: flex;
  height: 3.25rem;
  justify-content: space-around;
  left: 0;
  margin-top: 0.66rem;
  padding: 0.5rem 0 0.25rem;
  position: fixed;
  right: 0;
  z-index: 99;
  @media (min-width: 751px) {
    display: none;
  }
  > a {
    color: ${({ theme }) => theme.global.text};
    display: flex;
    flex-direction: column;
    font-size: 0.66rem;
    justify-content: flex-end;
    opacity: ${({ theme }) => theme.opctymid};
    overflow: hidden;
    text-decoration: none;
    text-align: center;
    width: 3.615rem;
    > span {
      white-space: nowrap;
    }
    > svg g,
    svg path {
      transition: fill 400ms ${({ theme }) => theme.cubbez};
    }
    &:hover,
    :focus {
      svg g {
        fill: ${({ theme }) => theme.global.blue};
      }
      svg path {
        stroke: ${({ theme }) => theme.global.body};
        fill: ${({ theme }) => theme.global.blue};
      }
    }
    &.active {
      svg g,
      svg path {
        fill: ${({ theme }) => theme.global.rust};
      }
      &:hover,
      :focus {
        svg g,
        svg path {
          fill: ${({ theme }) => theme.global.blue};
        }
      }
    }
  }
`

const BottomNav = () => (
  <BottomDiv id="bottom-nav">
    <Link to="/watercolour/" activeClassName="active" partiallyActive={true}>
      <WatercolourSVG />
      <span>Watercolour</span>
    </Link>
    <Link to="/acrylic/" activeClassName="active" partiallyActive={true}>
      <AcrylicSVG />
      <span>Acrylic</span>
    </Link>
    <Link to="/oil/" activeClassName="active" partiallyActive={true}>
      <OilSVG />
      <span>Oil</span>
    </Link>
    <Link to="/life-drawing/" activeClassName="active" partiallyActive={true}>
      <DrawingSVG />
      <span>Drawing</span>
    </Link>
    <Link to="/about/" activeClassName="active" partiallyActive={true}>
      <AboutSVG />
      <span>About</span>
    </Link>
    <Link to="/contact/" activeClassName="active" partiallyActive={true}>
      <ContactSVG />
      <span>Contact</span>
    </Link>
  </BottomDiv>
)

export default BottomNav
