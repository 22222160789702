import React from 'react'
import styled from 'styled-components'

import NavLinks from './navlinks'
import JbSvg from './svgs/jbh'

const NavDiv = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.global.nav};
  border-bottom: 1px solid ${({ theme }) => theme.global.blue};
  box-shadow: ${({ theme }) => theme.boxshdmid};
  display: flex;
  height: 2.5rem;
  justify-content: flex-end;
  padding: 0 0.5em;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  @media (max-width: 750px) {
    height: 2.5rem;
  }
`

const TopNav = ({ menuLinks, className }) => (
  <NavDiv id="navbar">
    <JbSvg />
    <NavLinks className={className} menuLinks={menuLinks} />
  </NavDiv>
)

export default TopNav
