import React, { useContext } from 'react'
import styled from 'styled-components'
import { ModeContext } from '../../context/ModeProvider'

import InstagramSVG from './svgs/instagramsvg'
import TwitterSVG from './svgs/twittersvg'
import SunMoonSVG from './svgs/sunmoonsvg'

const IconsDiv = styled.div`
  align-content: center;
  display: flex;
  justify-content: space-around;
  margin: 0.66em auto 0.125em;
  width: min(100%, 22rem);
  > span a,
  span button,
  span label {
    background-color: transparent;
    border: none;
    color: ${({ theme }) => theme.global.text};
    cursor: pointer;
    height: auto;
    opacity: ${({ theme }) => theme.opctyhi};
    transition: all ${({ theme }) => theme.translng};
    width: 2.36rem;
    will-change: transform;
  }
  > span a:hover,
  span a:focus,
  span button:hover,
  span button:focus,
  span label:hover,
  span label:focus {
    opacity: ${({ theme }) => theme.opctyfull};
    transform: scale(1.12, 1.12);
  }
  > span button svg,
  span label svg {
    height: 1.125rem;
    padding-bottom: 0;
    > path {
      fill: ${({ theme }) => theme.bluedrk};
    }
  }
  > span label input {
    position: absolute;
    visibility: hidden;
  }
`
// *** Social Icons Componenent ***
const SocialIcons = () => {
  const [darkMode, setDarkMode] = useContext(ModeContext)
  return (
    <IconsDiv>
      <InstagramSVG
        href="https://www.instagram.com/jossiemb/"
        title="Instagram"
      />
      <TwitterSVG href="https://twitter.com/JBNaturalDesign" title="Twitter" />
      <SunMoonSVG darkMode={darkMode} setDarkMode={setDarkMode} />
    </IconsDiv>
  )
}

export default SocialIcons
