const styledComponents = require('styled-components')
const { createGlobalStyle } = styledComponents

const sv = {
  white: 'hsl(0, 0%, 100%)',
  whitetns: 'hsla(0, 0%, 100%, 0.75)',
  bluelt: 'hsl(200, 36%, 55%)',
  bluedrk: 'hsl(200, 45%, 45%)',
  rustlt: 'hsl(31, 62%, 64%)',
  rustdrk: 'hsl(31, 62%, 35%)',
  accent: 'hsl(14, 100%, 70%)',
  greylt: 'hsl(0, 0%, 77%)',
  black: 'hsl(0, 4%, 10%)',
  coolblack: 'hsl(210, 14%, 14%)',
  accenttns: 'hsla(47, 21%, 65%, 0.77)',
  focusoutln: 'hsla(47, 21%, 65%, 0.5)',
  greytns: 'hsla(0, 0%, 78%, 0.1)',
  blacktns: 'hsla(0, 0%, 0%, 0.7)',
  radius: '2px',
  radiusM: '5px',
  radiusL: '7px',
  borderbtm: '0.029rem solid',
  boxshd:
    '0px 1px 3px hsla(0, 0%, 0%, 0.12), 0px 1px 2px hsla(0, 0%, 0%, 0.24)',
  boxshdmid:
    '0px 1px 9px hsla(0, 0%, 0%, 0.14), 0px 1px 20px hsla(0, 0%, 0%, 0.36)',
  boxshdhov:
    '0px 3px 12px 1px hsla(0, 0%, 0%, 0.14), 0px 2px 28px hsla(0, 0%, 0%, 0.5)',
  opctymid: '0.77',
  opctyhi: '0.88',
  opctyfull: '1',
  cubbez: 'cubic-bezier(0.25, 0.8, 0.25, 1)',
  translng: '600ms cubic-bezier(0.25, 0.8, 0.25, 1)',
}

const lightTheme = {
  ...sv,
  global: {
    blue: sv.bluedrk,
    rust: sv.rustdrk,
    body: sv.white,
    text: sv.blacktns,
    nav: sv.white,
  },
}

const darkTheme = {
  ...sv,
  global: {
    blue: sv.bluelt,
    rust: sv.rustlt,
    body: sv.black,
    text: sv.whitetns,
    nav: sv.coolblack,
  },
}

const GlobalStyle = createGlobalStyle`
  :root {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    font-family: "Lato", sans-serif;
    height: 100%;
    text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }
  ::-webkit-scrollbar {
    width: 0.5rem;
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.global.body};
  }
  ::-webkit-scrollbar-thumb {
    background: ${sv.accenttns};
    background-clip: content-box;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${sv.focusoutln};
  }
  ::-webkit-scrollbar-corner {
    background: ${({ theme }) => theme.global.body};
  }

  *,
  ::before,
  ::after {
    box-sizing: inherit;
  }

  *:focus {
    outline: none;
  }

  html {
    overflow-y: auto;
  }
  body {
    background-color: ${({ theme }) => theme.global.body};
    color: ${({ theme }) => theme.global.text};
    font-feature-settings: "kern", "liga", "clig", "calt";
    font-kerning: normal;
    margin: 0;
    padding: 0;
    word-wrap: break-word;
    -moz-osx-font-smoothing: grayscale;
    -moz-font-feature-settings: "kern", "liga", "clig", "calt";
    -ms-font-feature-settings: "kern", "liga", "clig", "calt";
    -webkit-font-feature-settings: "kern", "liga", "clig", "calt";
    -webkit-font-smoothing: antialiased;
  }
  a {
    background-color: transparent;
    font-weight: 400;
    text-decoration: none;
    -webkit-text-decoration-skip: objects;
  }
  .active {
    color: ${({ theme }) => theme.global.rust};
    opacity: ${({ theme }) => theme.opctyfull};
  }
  a:active,
  a:hover {
    outline: none;
  }
  img {
    border: 0 none;
    border-color: transparent; 
  }
  a img, a:link img, a:visited img {
   border: 0 none;
   border-color: transparent;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
  svg:not(:root) {
    overflow: hidden;
  }
  ol,
  ul {
    list-style: none;
  }
  li {
    margin: 0;
  }
  blockquote,
  q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: "";
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  button {
    font-weight: 400;
  }
`
module.exports = {
  darkTheme,
  lightTheme,
  GlobalStyle,
}
