import React from 'react'
import { SVGSpan } from '../stylecomp/multi.styles'
import Instagram from '../../images/instagram.inline.svg'

const InstagramSVG = ({ href, title }) => (
  <SVGSpan>
    <a
      href={href}
      target="_blank"
      rel="nofollow me noopener noreferrer"
      title={title}
      aria-label={title}
    >
      <Instagram />
    </a>
  </SVGSpan>
)

export default InstagramSVG
