import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const Nav = styled.nav`
  align-self: center;
  display: flex;
  height: 2rem;
  justify-content: flex-end;
  padding-bottom: 0.25em;
  @media (max-width: 750px) {
    display: none;
  }
  > ol {
    align-items: center;
    display: flex;
    margin: 0;
    > li {
      margin: 0;
      > p {
        font-size: 1rem;
      }
    }
  }
`
const StyledLi = styled.li`
  margin: 0;
  > p {
    font-size: 1rem;
  }
  > a {
    color: ${({ theme }) => theme.global.text};
    display: flex;
    font-size: 0.88em;
    font-weight: 300;
    height: 1.65rem;
    margin: 0 0.36em;
    opacity: ${({ theme }) => theme.opctyhi};
    position: relative;
    padding: 0.42em 0 0.265em;
    transition: all 400ms ease-in-out;
    will-change: color, opacity;

    &:focus,
    &:hover {
      color: ${({ theme }) => theme.global.blue};
      opacity: ${({ theme }) => theme.opctyfull};
      &::after {
        background-color: ${({ theme }) => theme.global.blue};
        opacity: ${({ theme }) => theme.opctyfull};
        transform: scale(1);
      }
    }
    &.active {
      color: ${({ theme }) => theme.global.rust};
      opacity: ${({ theme }) => theme.opctyfull};
    }
    &.active:hover {
      color: ${({ theme }) => theme.global.blue};
      opacity: ${({ theme }) => theme.opctyfull};
    }
    &.active:focus {
      color: ${({ theme }) => theme.global.blue};
      opacity: ${({ theme }) => theme.opctyfull};
    }
    &::after {
      background-color: transparent;
      bottom: 0;
      content: '';
      height: 0.06rem;
      left: 0;
      opacity: 0;
      position: absolute;
      transform: scale(0);
      transition: all 400ms ${({ theme }) => theme.cubbez};
      transform-origin: center;
      width: 100%;
    }
    &.active::after {
      background-color: ${({ theme }) => theme.global.rust};
      opacity: ${({ theme }) => theme.opctyfull};
      transform: scale(1);
    }
    &.active:hover::after {
      background-color: ${({ theme }) => theme.global.blue};
      opacity: ${({ theme }) => theme.opctyfull};
    }
    &.active:focus::after {
      background-color: ${({ theme }) => theme.global.blue};
      opacity: ${({ theme }) => theme.opctyfull};
    }
  }
`
// *** Navigation Links Component ***
const NavLinks = ({ handleMenu, className, menuLinks }) => {
  const preMenu = [...menuLinks]
  preMenu.splice(-2, 2)
  const Menu = preMenu.slice(1)

  return (
    <Nav className={className}>
      <ol>
        {Menu.map((link) => (
          <StyledLi key={link.name}>
            <Link
              onClick={handleMenu}
              to={link.link}
              activeClassName="active"
              partiallyActive={true}
              activeStyle={{
                color: `${({ theme }) => theme.global.rust}`,
                opacity: `${({ theme }) => theme.opctyfull}`,
              }}
            >
              <p>{link.name}</p>
            </Link>
          </StyledLi>
        ))}
      </ol>
    </Nav>
  )
}

export default NavLinks
