exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-acrylic-js": () => import("./../../../src/pages/acrylic.js" /* webpackChunkName: "component---src-pages-acrylic-js" */),
  "component---src-pages-adminjoss-js": () => import("./../../../src/pages/adminjoss.js" /* webpackChunkName: "component---src-pages-adminjoss-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-life-drawing-js": () => import("./../../../src/pages/life-drawing.js" /* webpackChunkName: "component---src-pages-life-drawing-js" */),
  "component---src-pages-oil-js": () => import("./../../../src/pages/oil.js" /* webpackChunkName: "component---src-pages-oil-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-watercolour-js": () => import("./../../../src/pages/watercolour.js" /* webpackChunkName: "component---src-pages-watercolour-js" */),
  "component---src-templates-acryl-js": () => import("./../../../src/templates/acryl.js" /* webpackChunkName: "component---src-templates-acryl-js" */),
  "component---src-templates-draws-js": () => import("./../../../src/templates/draws.js" /* webpackChunkName: "component---src-templates-draws-js" */),
  "component---src-templates-oils-js": () => import("./../../../src/templates/oils.js" /* webpackChunkName: "component---src-templates-oils-js" */),
  "component---src-templates-wc-js": () => import("./../../../src/templates/wc.js" /* webpackChunkName: "component---src-templates-wc-js" */)
}

