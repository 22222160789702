import React from 'react'
import styled from 'styled-components'
import Up from '../../images/up.inline.svg'

const StyledUp = styled(Up)`
  > path:first-of-type {
    fill: ${({ theme }) => theme.global.body};
    fill-opacity: 1;
  }
`

const UpSVG = () => (
  <>
    <StyledUp />
  </>
)

export default UpSVG
