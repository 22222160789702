import React, { useContext, useEffect } from 'react'
import { Script, useStaticQuery, graphql } from 'gatsby'
import isPropValid from '@emotion/is-prop-valid'
import { StyleSheetManager, ThemeProvider } from 'styled-components'
import { ModeContext } from '../../context/ModeProvider'

import { WrapDiv, Main } from './stylecomp/multi.styles'
import { GlobalStyle, darkTheme, lightTheme } from './stylecomp/theme'

import Footer from './footer'
import TopNav from './topnav'

const Layout = ({ children }) => {
  const [darkMode] = useContext(ModeContext)

  useEffect(() => {
    let localTheme
    if (localStorage) {
      localTheme = localStorage.getItem('jbhtheme')
      if (localTheme === 'dark') {
        localStorage.setItem('jbhtheme', 'dark')
      }
      if (localTheme === 'light') {
        localStorage.setItem('jbhtheme', 'light')
      }
      if (darkMode === true) {
        localStorage.setItem('jbhtheme', 'dark')
      } else {
        localStorage.setItem('jbhtheme', 'light')
      }
    }
  }, [darkMode])

  const data = useStaticQuery(graphql`
    query headertitleQuery {
      site {
        siteMetadata {
          title
          menuLinks {
            name
            link
          }
        }
      }
    }
  `)
  return (
    <>
      <Script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-M62V5DZXPS"
        strategy="post-hydrate"
        forward={[`gtag`]}
      />
      <Script
        id="gtag-config"
        strategy="post-hydrate"
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
  window.gtag = function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'G-M62V5DZXPS', { send_page_view: false })`,
        }}
      />
      <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
        <GlobalStyle />
        <StyleSheetManager shouldForwardProp={isPropValid}>
          <WrapDiv>
            <TopNav menuLinks={data.site.siteMetadata?.menuLinks} />
            <Main>{children}</Main>
            <Footer />
          </WrapDiv>
        </StyleSheetManager>
      </ThemeProvider>
    </>
  )
}

export default Layout
