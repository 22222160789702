import React from 'react'
import { SVGSpan } from '../stylecomp/multi.styles'
import Sun from '../../images/sun.inline.svg'
import Moon from '../../images/moon.inline.svg'

const SunMoonSVG = ({ darkMode, setDarkMode }) => {
  return (
    <SVGSpan>
      <button type="button" onClick={() => setDarkMode((prev) => !prev)}>
        {darkMode ? <Moon /> : <Sun />}
        <p style={{ fontSize: '0.66rem', margin: '-0.2em 0 0' }}>
          {darkMode ? 'dark' : 'light'}
        </p>
      </button>
    </SVGSpan>
  )
}
export default SunMoonSVG
